import { Cancel, CheckCircleRounded, Done, NearbyError } from "@mui/icons-material";
import { Avatar, Box, Button, Checkbox, DialogContent, DialogTitle, Divider, FormLabel, Grid, LinearProgress, List, ListItem, Modal, ModalClose, ModalDialog, Radio, RadioGroup, Sheet, Textarea, Typography, radioClasses } from "@mui/joy";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../Context";
import { Profile } from '../../utilities/identity';
import { useHash } from "react-use";

export default function UnauthorisedCreate() {
    const [value, setValue] = useState<string[]>([]);

    const [justification, setJustification] = useState<string>("");

    const [successOpen, setSuccessOpen] = useState<boolean>(false);
    const [waitOpen, setWaitOpen] = useState<boolean>(false);

    const [ticketID, setTicketID] = useState<string>("");
    const { rootUser, core } = useGlobalContext(); // Logged In User.

    const [ready, setReady] = useState<boolean>(false);
    const [alreadyOpen, setAlreadyOpen] = useState<boolean>(false);

    // eslint-disable-next-line
    const [hash, setHash] = useHash();

    useEffect(() => {
        if (rootUser && core) {
            Profile.getOpenRequests(core, rootUser).then((resp) => {
                if (resp.statusCode === 200) {
                    const openRequests = (resp.body as FreshOpenRequestResponse).tickets.filter(ticket => ticket.subject.includes("Identity & Access: Role Request - Create"));
                    if (openRequests.length > 0) {
                        setTicketID(openRequests[0].id);
                        setAlreadyOpen(true);
                        setSuccessOpen(true);
                    }
                }
                setReady(true);
            });
        }
    }, [rootUser]);

    const roleMap = {
        "Staff": "createStaff",
        "Teacher": "createTeacher",
        "External Consultant": "createConsultant",
        "Tour Director": "createTD",
    }

    return (
        !rootUser || !ready ? <Box>
            <Typography mt={2} level="body-md">
                Looks like you don't have the right roles to create new employee accounts but you can request it below. To do so, select the types of account you would like to be able to create and make sure you enter a business justification so that the approval can be granted as quickly as possible.
            </Typography>
            <LinearProgress color="neutral" thickness={2} sx={{ mt: 6 }} />
        </Box> :
            <Box>
                <Modal open={waitOpen} onClose={() => setWaitOpen(false)} >
                    <ModalDialog color={"neutral"} role="alertdialog" >
                        <ModalClose color='neutral' variant='plain' disabled={true} sx={{ m: 1 }} />
                        <DialogTitle >
                            Please wait...
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <LinearProgress />
                        </DialogContent>
                    </ModalDialog>
                </Modal>
                <Modal open={successOpen} >
                    <ModalDialog color={"success"} role="alertdialog" >
                        <DialogTitle >
                            {alreadyOpen ? "Your request is pending approval." : "Request Submitted"}
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Button sx={{ mt: 4 }} color="success" onClick={() => {
                                window.open(`https://ithelp.ef.com/a/tickets/${ticketID}?current_tab=approvals`, '_blank')?.focus();
                            }}>
                                View Request
                            </Button>
                            <Button onClick={() => {
                                setHash("#/people/me");
                            }}>Go Back</Button>
                        </DialogContent>
                    </ModalDialog>
                </Modal>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (justification && core) {
                        const justificationContent = justification.split("\n").join(" ").split("\"").join("'").trim();
                        setWaitOpen(true);
                        Profile.requestCreateRole(core, {
                            roles: value.map(item => roleMap[item as "Staff" | "Teacher" | "External Consultant" | "Tour Director"]),
                            justification: justificationContent,
                            upn: rootUser?.userPrincipalName
                        }).then((resp) => {
                            if (resp.statusCode === 200) {
                                setTicketID((resp.body as any).service_request.id);
                                setWaitOpen(false);
                                setSuccessOpen(true);
                            }
                        });

                    }
                }}>
                    <Typography mt={1} level="body-md">
                        Looks like you don't have the right roles to create new employee accounts but you can request it below. To do so, select the types of account you would like to be able to create and make sure you enter a business justification so that the approval can be granted as quickly as possible.
                    </Typography>
                    <Box mt={4}>
                        <Divider role="presentation" sx={{
                            mt: 2,
                            mb: 2,
                            gridColumn: "1/-1"
                        }} />
                        {
                            rootUser.usageLocation === "CA" || rootUser.usageLocation === "US" ?
                                <Typography sx={{ mb: 2 }} color='warning' fontSize={"lg"} startDecorator={<NearbyError />}>If you are hiring for the US and Canada, Oracle will now create and manage staff accounts. If you require assistance please reach out to your HR team.</Typography>
                                : <></>
                        }
                        <Typography mb={1.5}>Please select one or more accout types below.</Typography>
                        <List
                            orientation="horizontal"
                            wrap
                            sx={{
                                mb: 4,
                                '--List-gap': '8px',
                                '--ListItem-radius': '20px',
                                '--ListItem-minHeight': '32px',
                                '--ListItem-gap': '4px',
                            }}
                        >
                            {['Staff', 'Teacher', 'External Consultant', 'Tour Director'].map(
                                (item) => (
                                    <ListItem key={item}>
                                        {value.includes(item) && (
                                            <Done
                                                color="primary"
                                                sx={{ ml: -0.5, zIndex: 2, pointerEvents: 'none' }}
                                            />
                                        )}
                                        <Checkbox
                                            size="sm"
                                            disableIcon
                                            overlay
                                            label={item}
                                            checked={value.includes(item)}
                                            variant={value.includes(item) ? 'soft' : 'outlined'}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (event.target.checked) {
                                                    setValue((val) => [...val, item]);
                                                } else {
                                                    setValue((val) => val.filter((text) => text !== item));
                                                }
                                            }}
                                            slotProps={{
                                                action: ({ checked }) => ({
                                                    sx: checked
                                                        ? {
                                                            border: '1px solid',
                                                            borderColor: 'primary.500',
                                                        }
                                                        : {},
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                ),
                            )}
                        </List>
                        <Divider key={0} role="presentation" sx={{
                            mt: 2,
                            mb: 2,
                            gridColumn: "1/-1"
                        }} />
                        <Typography mb={1.5}>Please describe the usecase for creating accounts through EF Identity & Access. This will be vetted by your manager and someone in our team, so please be as descriptive as possible.</Typography>
                        <Textarea required minRows={4} onChange={(e) => {
                            setJustification(e.target.value ? e.target.value : "");
                        }} />
                        <Box
                            id='bottom-buttons'
                            sx={{
                                gridColumn: '1/-1',
                                justifyContent: 'flex-end',
                                display: 'flex',
                                gap: 1,
                                paddingTop: 1
                            }}
                        >
                            <Button
                                type="submit"
                                sx={{
                                    borderRadius: "0px",
                                }} size="sm" >Submit</Button>
                        </Box>
                    </Box>
                </form>
            </Box>
    )
}

interface TicketInfo {
    subject: string;
    id: string;
}

interface FreshOpenRequestResponse {
    tickets: TicketInfo[];
}

export function UnauthorisedSearch() {
    const [role, setRole] = useState<string>("Basic");
    const [justification, setJustification] = useState<string>("");

    const [successOpen, setSuccessOpen] = useState<boolean>(false);
    const [waitOpen, setWaitOpen] = useState<boolean>(false);

    const [ticketID, setTicketID] = useState<string>("");
    const { rootUser, core } = useGlobalContext(); // Logged In User.

    const [ready, setReady] = useState<boolean>(false);
    const [alreadyOpen, setAlreadyOpen] = useState<boolean>(false);

    // eslint-disable-next-line
    const [hash, setHash] = useHash();

    const roleMap = {
        "Administrator": "userAdmin",
        "Finance": "financeAdmin",
        "Basic": "userSearch"
    }

    useEffect(() => {
        if (rootUser && core) {
            Profile.getOpenRequests(core, rootUser).then((resp) => {
                if (resp.statusCode === 200) {
                    const openRequests = (resp.body as FreshOpenRequestResponse).tickets.filter(ticket => ticket.subject.includes("Identity & Access: Role Request - Search"));
                    if (openRequests.length > 0) {
                        setTicketID(openRequests[0].id);
                        setAlreadyOpen(true);
                        setSuccessOpen(true);
                    }
                }
                setReady(true);
            });
        }
    }, [rootUser]);

    return (
        !rootUser || !ready ? <Box>
            <Typography mt={2} level="body-md">
                Looks like you don't have the right roles to search for existing employee accounts, but you can request it below. To do so, select the level of access you require and make sure you enter a business justification so that the approval can be granted as quickly as possible.
            </Typography>
            <LinearProgress color="neutral" thickness={2} sx={{ mt: 6 }} />
        </Box> :
            <Box>
                <Modal open={waitOpen} onClose={() => setWaitOpen(false)} >
                    <ModalDialog color={"neutral"} role="alertdialog" >
                        <ModalClose color='neutral' variant='plain' disabled={true} sx={{ m: 1 }} />
                        <DialogTitle >
                            Please wait...
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <LinearProgress />
                        </DialogContent>
                    </ModalDialog>
                </Modal>
                <Modal open={successOpen} >
                    <ModalDialog color={"success"} role="alertdialog" >
                        <DialogTitle >
                            {alreadyOpen ? "Your request is pending approval." : "Request Submitted"}
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Button sx={{ mt: 4 }} color="success" onClick={() => {
                                window.open(`https://ithelp.ef.com/a/tickets/${ticketID}?current_tab=approvals`, '_blank')?.focus();
                            }}>
                                View Request
                            </Button>
                            <Button onClick={() => {
                                setHash("#/people/me");
                            }}>Go Back</Button>
                        </DialogContent>
                    </ModalDialog>
                </Modal>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (justification && core) {
                        const justificationContent = justification.split("\n").join(" ").split("\"").join("'").trim();
                        setWaitOpen(true);
                        Profile.requestSearchRole(core, {
                            role: roleMap[role as "Administrator" | "Finance" | "Basic"],
                            justification: justificationContent,
                            upn: rootUser?.userPrincipalName
                        }).then((resp) => {
                            if (resp.statusCode === 200) {
                                setTicketID((resp.body as any).service_request.id);
                                setWaitOpen(false);
                                setSuccessOpen(true);
                            }
                        });

                    }
                }}>
                    <Typography mt={2} level="body-md">
                        Looks like you don't have the right roles to search for existing employee accounts, but you can request it below. To do so, select the level of access you require and make sure you enter a business justification so that the approval can be granted as quickly as possible.
                    </Typography>
                    <Box mt={4}>
                        <Divider key={0} role="presentation" sx={{
                            mt: 2,
                            mb: 2,
                            gridColumn: "1/-1"
                        }} />
                        <Typography mb={1.5}>Please select a level of access below.</Typography>
                        <Grid container
                            spacing={{ xs: 2, md: 2 }}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            sx={{ flexGrow: 1, alignContent: "space-between" }}
                        >
                            <Grid xs={6} md={6} key={0}
                                sx={{
                                    width: {
                                        xs: "100%",
                                        md: "45%"
                                    }
                                }}
                            >
                                <RadioGroup
                                    overlay
                                    onChange={(evt) => {
                                        setRole(evt.target.id);
                                    }}
                                    sx={{
                                        flexDirection: {
                                            xs: 'column',
                                            md: 'row'
                                        },
                                        gap: 2,
                                        [`& .${radioClasses.checked}`]: {
                                            [`& .${radioClasses.action}`]: {
                                                inset: -1,
                                                border: '3px solid',
                                                borderColor: 'primary.500',
                                            },
                                        },
                                        [`& .${radioClasses.radio}`]: {
                                            display: 'contents',
                                            '& > svg': {
                                                zIndex: 2,
                                                position: 'absolute',
                                                top: '-8px',
                                                right: '-8px',
                                                bgcolor: 'background.surface',
                                                borderRadius: '50%',
                                            },
                                        },
                                    }}
                                >
                                    <Sheet
                                        key="Administrator"
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 'md',

                                            boxShadow: 'sm',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1.5,
                                            p: 2,
                                            minWidth: 120,
                                        }}
                                    >
                                        <Radio id={"Administrator"} value={"Administrator"} checked={role === "Administrator"} checkedIcon={<CheckCircleRounded />} />
                                        <Avatar variant="soft" size="sm" />
                                        <FormLabel htmlFor={"Administrator"}>{"Administrator"}</FormLabel>
                                    </Sheet>
                                    <Sheet
                                        key="Finance"
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 'md',

                                            boxShadow: 'sm',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1.5,
                                            p: 2,
                                            minWidth: 120,
                                        }}
                                    >
                                        <Radio id={"Finance"} value={"Finance"} checked={role === "Finance"} checkedIcon={<CheckCircleRounded />} />
                                        <Avatar variant="soft" size="sm" />
                                        <FormLabel htmlFor={"Finance"}>{"Finance"}</FormLabel>
                                    </Sheet>
                                    <Sheet
                                        key="Basic"
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 'md',
                                            boxShadow: 'sm',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1.5,
                                            p: 2,
                                            minWidth: 120,
                                        }}
                                    >
                                        <Radio id={"Basic"} value={"Basic"} checked={role === "Basic"} checkedIcon={<CheckCircleRounded />} />
                                        <Avatar variant="soft" size="sm" />
                                        <FormLabel htmlFor={"Basic"}>{"Basic"}</FormLabel>
                                    </Sheet>
                                </RadioGroup>
                            </Grid>
                            <Grid xs={6} md={6} key={1}
                                sx={{
                                    width: {
                                        xs: "100%",
                                        lg: "45%"
                                    }
                                }}
                            >
                                <Typography startDecorator={<Cancel sx={{ color: "#8C1D18" }} />}>View sensitive profile content such as personal phone and email.</Typography>
                                <Typography startDecorator={role === "Administrator" ? <CheckCircleRounded color="success" /> : <Cancel sx={{ color: "#91312d" }} />}> Edit, Expire and Extend non-HR accounts.</Typography>
                                <Typography startDecorator={role === "Administrator" ? <CheckCircleRounded color="success" /> : <Cancel sx={{ color: "#91312d" }} />}> View account expiry date.</Typography>
                                <Typography startDecorator={role === "Administrator" || role === "Finance" ? <CheckCircleRounded color="success" /> : <Cancel sx={{ color: "#91312d" }} />}> View finance / payroll codes.</Typography>
                                <Typography startDecorator={role === "Administrator" || role === "Finance" || role === "Basic" ? <CheckCircleRounded color="success" /> : <Cancel sx={{ color: "#91312d" }} />}> View basic work information.</Typography>
                            </Grid>
                        </Grid>
                        <Divider role="presentation" sx={{
                            mt: 2,
                            mb: 2,
                            gridColumn: "1/-1"
                        }} />
                        <Typography mb={1.5}>Please describe the usecase for searching and managing accounts through EF Identity & Access. This will be vetted by your manager and someone in our team, so please be as descriptive as possible.</Typography>
                        <Textarea required minRows={4} onChange={(e) => {
                            setJustification(e.target.value ? e.target.value : "");
                        }} />
                        <Box
                            id='bottom-buttons'
                            sx={{
                                gridColumn: '1/-1',
                                justifyContent: 'flex-end',
                                display: 'flex',
                                gap: 1,
                                paddingTop: 1
                            }}
                        >
                            <Button
                                // disabled={formSubmitted}
                                type="submit"
                                sx={{
                                    borderRadius: "0px",
                                }} size="sm" >Submit</Button>
                        </Box>
                    </Box>
                </form>
            </Box>
    )
}