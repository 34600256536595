import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { EmojiFoodBeverage, Face, FingerprintOutlined, Help, History, Luggage, MapOutlined, Microsoft, PrecisionManufacturing, Public, School, Work, WorkHistory } from '@mui/icons-material';
import { Avatar, Box, Skeleton, Tooltip, Typography } from '@mui/joy';
import * as lookup from "country-code-lookup";
import { ReactElement, useEffect, useState } from 'react';
import { useGlobalContext } from '../../Context';
import { ImageGetter } from '../../utilities/assets';
import { getExpiryDateChip } from '../LifecycleChip';
import FileUploadAvatar from './FileUploadAvatar';

interface AvatarCardProps {
    profile?: AccountInfo | undefined | null;
    isSelf?: boolean;
}

const toTitleCase = (str: string) => {
    str = str.toLowerCase();
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

const getAccountTypeIcon = (profile: AccountInfo | null | undefined) => {
    switch (profile?.Basic?.accountType) {
        case "STAFF":
            return <EmojiFoodBeverage />;
        case "TEACHER":
            return <School />
        case "EXTERNAL CONSULTANT":
            return <WorkHistory />;
        case "SERVICE ACCOUNT":
            return <PrecisionManufacturing />;
        case "TOUR DIRECTOR":
            return <Luggage />;
        default:
            return <Help />;
    }
}


const checkLicense = (profile: AccountInfo | null | undefined) => {
    if (profile) {
        if (profile?.Licensing?.m365License) {
            if (profile?.Licensing?.m365License.includes("A1")) {
                return "Microsoft 365 Education A1";
            }
            else if (profile?.Licensing?.m365License.includes("A3")) {
                return "Microsoft 365 Education A3";
            }
            else if (profile?.Licensing?.m365License.includes("A5")) {
                return "Microsoft 365 Education A5";
            }
            else {
                return "Invalid License";
            }
        } else {
            return "None";
        }
    } else {
        return null;
    }
}

const SimpleCardItem = (name: string, value: string | null, hideOnMobile: boolean, icon: ReactElement) => {
    const style = hideOnMobile ? {
        display: {
            lg: "grid",
            sm: "none",
            xs: "none",
        },
        gridTemplateColumns: {
            lg: "10% 95%",
            xs: "15% 95%"
        },
        padding: 2,
        pt: 1,
        pb: 0
    } : {
        display: "grid",
        gridTemplateColumns: {
            lg: "10% 95%",
            xs: "15% 95%"
        },
        padding: 2,
        pt: 1,
        pb: 0
    }
    return (
        <Tooltip title={name} placement="left" size="sm" arrow>
            <Box sx={style}>
                {icon}

                <Typography sx={{ pl: { xs: 1, sm: 0, md: 0, lg: 0 } }}>
                    <Skeleton loading={value ? false : true} animation="wave">
                        {value || "exampleuser.exampleuser@ef.com"}
                    </Skeleton>
                </Typography>

            </Box>
        </Tooltip>
    )
}

export default function AvatarCard({ profile, isSelf }: AvatarCardProps) {
    const { profileImage, core, directReports, metadata } = useGlobalContext();
    const [image, setImage] = useState<string | null>();
    const [expiryLabel, setExpiryLabel] = useState<JSX.Element>();

    useEffect(() => {
        if (profile && core && !isSelf) {
            new ImageGetter(core).getPicture(profile.id, (directReports || [])?.filter(user => user.id === profile.id).length > 0, false).then((image) => {
                setImage(image);
            });
        }
        if (profile) {
            setExpiryLabel(getExpiryDateChip(profile, true))
        }
    }, [profile, core])

    const getOfficeAddress = () => {
        const officeMatch = metadata?.sites.find(site => site.code === profile?.officeLocation);
        if (officeMatch) {
            return `${officeMatch.address}, ${officeMatch.city}, ${officeMatch.postalCode}`
        }else {
            return undefined;
        }
    }

    return (
        <Box
            sx={{
                padding: 0,
                display: 'grid',
                pb: 2,
                pt: 2,
                justifyContent: "space-between",
                gridTemplateColumns: {
                    xs: '100%',
                    sm: '150px 85%',
                    lg: '150px 85%',
                },
                gap: 2,
                '& > hr': {
                    gridColumn: '1/-1',
                },
            }}
        >
            {
                isSelf ?
                    <Box
                        sx={{
                            alignItems: "center",
                            textAlign: "center"
                        }}>
                        <FileUploadAvatar
                            image={profileImage ? profileImage : ""}
                            profile={profile ? profile : undefined}
                        >
                            {profile && expiryLabel ? expiryLabel : ""}
                        </FileUploadAvatar>
                    </Box>
                    :
                    <Box
                        sx={{
                            alignItems: "center",
                            textAlign: "center"
                        }}>
                        <Avatar
                            size="lg"
                            src={image ? image : undefined}
                            sx={{
                                '--Avatar-size': '150px',
                                margin: "auto"
                            }}
                        >EF<Skeleton loading={profile ? false : true} animation="wave" />
                        </Avatar>
                        {profile ? getExpiryDateChip(profile, true) : ""}
                    </Box>
            }
            <Box sx={{
                alignItems: {
                    xs: "center",
                    md: "flex-start",
                    sm: "flex-start",
                    lg: "flex-start"
                },
                textAlign: {
                    xs: "center",
                    md: 'left',
                    sm: 'left',
                    lg: 'left'
                },
                display: {
                    lg: "grid",
                    md: "grid",
                    sm: "grid",
                    xs: "flex"
                },
                flexFlow: {
                    xs: "column"
                },
                gridTemplateColumns: {
                    xs: '100%',
                    sm: '50%',
                    lg: '50% 50%',
                },
                pb: 4
            }}>
                {SimpleCardItem("Name", profile?.displayName || "", false, <Face />)}
                {SimpleCardItem("Job Title", profile?.jobTitle || "", false, <Work />)}
                {SimpleCardItem("Username", profile?.userPrincipalName || "", false, <FingerprintOutlined />)}
                {
                    SimpleCardItem(
                        "Last Updated",
                        profile && profile?.extension_fa88af559d18423d9b5e41b82d8a46a1_lastChanged ? `${new Date(profile?.extension_fa88af559d18423d9b5e41b82d8a46a1_lastChanged || "").toLocaleDateString()} at ${new Date(profile?.extension_fa88af559d18423d9b5e41b82d8a46a1_lastChanged || "").toLocaleTimeString()}` : "No recent changes",
                        false,
                        <History />
                    )
                }
                {SimpleCardItem("Office 365 License", checkLicense(profile), true, <Microsoft />)}
                {SimpleCardItem("Address",  getOfficeAddress() || "Could not find address.", true, <MapOutlined />)}
                {SimpleCardItem("Account Type", toTitleCase(profile?.Basic?.accountType || ""), true, getAccountTypeIcon(profile))}
                {SimpleCardItem("Work Country", profile ? lookup.byIso(profile?.usageLocation || "")?.country || "" : "", true, <Public />)}
            </Box>
        </Box>

    );
}
