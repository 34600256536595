import { Site } from "@efwwit/integration-hub-sdk/dist/nodejs/types/identity";
import { AutocompleteOption, Avatar, ListItemContent, ListItemDecorator, Typography } from "@mui/joy";
import { useState } from "react";
import { useGlobalContext } from "../../Context";
import { Core } from "../../utilities/core";
import { Profile } from "../../utilities/identity";
import { Metadata, MetadataTableItem } from "../../utilities/metadata";
import AutocompleteComponent from "./CustomAutoComplete";
import { FormInputProps, Labels, ManagerListItem, ProductDivisionItem, SiteListItem } from './constructs';

//#region Metadata getters.
const getTableName = (name: string): string => {
    const nameMapping: { [key: string]: string } = {
        "Product": "product",
        "Product Market": "productMarket",
        "Company": "companyCode",
        "Sales Market": "salesMarket",
        "Cost Center": "costCenter",
        "Location": "sites",
        "Department": "productDivision",
        "Primary Domain": "domains",
        "Account Type": "accountTypes"
    }
    return nameMapping[name];
}

interface RoleMapping {
    [key: string]: string[];
}

const roleMapping: RoleMapping = {
    "globalAdmin": [
        "STAFF",
        "EXTERNAL CONSULTANT",
        "TEACHER",
        "SERVICE ACCOUNT",
        "TOUR DIRECTOR",
        "CORPORATE TEACHER"
    ],
    "createStaff": [
        "STAFF"
    ],
    "createConsultant": [
        "EXTERNAL CONSULTANT"
    ],
    "createTD": [
        "TOUR DIRECTOR"
    ],
    "userAdmin": [
        "STAFF"
    ],
    "createTeacher": [
        "TEACHER",
        "CORPORATE TEACHER"
    ]
};

const getOptionsForRole = (userRoles: string[], usageLocation: string): string[] => {
    let roles = [];
    if (usageLocation === "US" || usageLocation === "CA") {
        roles = Array.from(
            new Set<string>(
                userRoles.flatMap(role => roleMapping[role] || []).filter(type => type !== "STAFF" && type !== "TEACHER" && type !== "SERVICE ACCOUNT" && type !== "CORPORATE TEACHER")
            )
        );
    } else {
        roles = Array.from(
            new Set<string>(
                userRoles.flatMap(role => roleMapping[role] || [])
            )
        );
    }
    return roles;
}
// Example usage:

const getTableOptions = (label: string, metadata: Metadata | null): string[] => {
    const { rootUser } = useGlobalContext();
    const tableName = getTableName(label);

    let optionsArray: any[] = [];
    if (metadata?.accountTypes) {
        const tableData = metadata[tableName as keyof Metadata] as MetadataTableItem[];

        switch (label) {
            case "Office Location":
                optionsArray = (tableData as unknown as Site[]).map((item) => {
                    return {
                        displayName: item?.displayName,
                        country: item?.country?.name,
                        countryCode: item?.country?.codes?.alpha2,
                        officeCode: item?.code,
                        label: item?.code
                    }
                });
                break;
            case "Department":
                optionsArray = tableData.flatMap((item) => {
                    const parsedDepartmentItem = parseDepartmentObject(item as unknown as ProductDivisionItem);
                    return parsedDepartmentItem
                });
                break;
            case "Primary Domain":
                optionsArray = tableData.map((item: any) => {
                    return {
                        label: item.domain,
                        code: item.domain,
                    }
                });
                break;
            case "Account Type":
                if (rootUser?.RBAC?.iaaRole) {
                    optionsArray = getOptionsForRole(rootUser?.RBAC?.iaaRole, rootUser?.usageLocation).map((value) => {
                        return {
                            label: value,
                            code: value
                        }
                    });
                }
                break;
            default:
                optionsArray = tableData.map((item) => {
                    return {
                        label: item.code,
                        code: item.code,
                        description: item.description || undefined
                    }
                });
                break;
        }

    }
    return optionsArray;
}

const parseDepartmentObject = (product: ProductDivisionItem) => {
    return product.divisions.map((division: string) => ({
        label: division,
        product: product.product
    }));
}

const profileLookup = async (core: Core, name: string): Promise<ManagerListItem[]> => {
    const nameList = await Profile.queryLookup(core, name);
    if (nameList) {
        return nameList.map((user) => {
            return {
                ...user,
                label: user.userPrincipalName
            }
        }) as unknown as ManagerListItem[];
    } else {
        return [];
    }
}
// const getProfileImage = async (user: string): Promise<string> => {
//     const profileImage = await ProfileImage.retrieveProfileImage(core as Core, user);
//     return profileImage;
// }

//#endregion

//#region Custom rendering for auto complete options.
const managerRenderOption = (props: any, { displayName, userPrincipalName }: ManagerListItem, image?: string): JSX.Element => {
    return (
        <AutocompleteOption {...props}>
            <ListItemDecorator>
                <Avatar size="sm" src={image ? image : undefined} ></Avatar>
            </ListItemDecorator>
            <ListItemContent sx={{ fontSize: 'sm' }}>
                {displayName}
                <Typography level="body-xs">
                    {userPrincipalName}
                </Typography>
            </ListItemContent>
        </AutocompleteOption>
    )
}

const renderOfficeLocation = (props: any, { country, countryCode, officeCode, displayName }: SiteListItem): JSX.Element => {
    return (
        <AutocompleteOption {...props}>
            <ListItemDecorator>
                <img
                    loading="eager"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`}
                    alt={country}
                />
            </ListItemDecorator>
            <ListItemContent sx={{ fontSize: 'sm' }}>
                {officeCode}
                <Typography level="body-xs">
                    {displayName}
                </Typography>
            </ListItemContent>
        </AutocompleteOption>
    );
}

const renderGeneric = (props: any, input: { label: string, code: string, description: string }) => {
    return (
        <AutocompleteOption {...props}>
            <ListItemContent sx={{ fontSize: 'sm' }}>
                {input.label}
                <Typography level="body-xs">
                    {input.description}
                </Typography>
            </ListItemContent>
        </AutocompleteOption>
    );
}

/**
 * Maps a label to a specific render option.
 * @param {Labels} label - The label to map.
 * @return {Function} The corresponding render option.
 */
const getMappedLabelRenderOption = (label: Labels) => {
    switch (label) {
        case "Manager":
            return managerRenderOption;
        case "Office Location":
            return renderOfficeLocation;
        default:
            return renderGeneric;
    }
};

//#endregion

let timeout: NodeJS.Timeout;
export default function ListFormInput({ name, value, label, isLocked }: FormInputProps) {
    const { core, metadata } = useGlobalContext();
    let options: any[] = [];
    const [profiles, setProfiles] = useState([] as ManagerListItem[]);

    if (label !== "Manager") {
        options = getTableOptions(label, metadata);
    } else {
        options = profiles;
    }

    const handleProfileLookup = async (event: any) => {
        if (core) {
            const searchTerm = event.target ? (event.target as HTMLTextAreaElement).value : "";
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                if (searchTerm && searchTerm.length > 0) {
                    profileLookup(core, searchTerm).then((nameList) => {
                        setProfiles(nameList);
                    });
                } else {
                    setProfiles([]);
                }
            }, 500);
        }
    }
    //#endregion

    return (
        <AutocompleteComponent
            core={core as Core}
            label={label}
            isLocked={isLocked}
            name={name}
            options={options}
            value={value}
            labelRenderOption={getMappedLabelRenderOption(label)}
            handleKeyUp={label === "Manager" ? handleProfileLookup : undefined}
            groupBy={label === "Department" ? (option) => { return (option as unknown as ProductDivisionItem).product } : undefined}
        />
    )
}